import React from "react";
import Button from "../../Button/Button";
import Footer from "../../Footer/Footer";
import {navigate} from "gatsby";

const styles = require("./Security.module.scss");

interface Props {}

const Security: React.FunctionComponent<Props> = () => {

	return (
		<div className={styles.Security}>

			<div className={styles.Hero}>
				<div className={styles.Top}>
					<h1>Security & Privacy at Prestavi</h1>
					<p>Security and privacy is at the core of our company. To us, security isn't just about technology, it's about trust. </p>
				</div>
				<div className={styles.Bottom}>
					<svg xmlns="http://www.w3.org/2000/svg" width="64.028" height="77.804" viewBox="0 0 64.028 77.804">
						<g transform="translate(-625 -397)">
							<g transform="translate(334.826 -1547.249)">
								<path d="M322.188,2022.053c-40.967-21.785-30.928-70.3-30.928-70.3l30.928-7.5,30.928,7.5S363.155,2000.268,322.188,2022.053Z" fill="#fff"/>
								<path d="M325.789,2023.253c-37.622-20.007-28.4-64.562-28.4-64.562l28.4-6.89,28.4,6.89S363.412,2003.246,325.789,2023.253Z" transform="translate(-3.601 -4.376)" fill="#5583ed"/>
								<g transform="translate(307.346 1967.382)">
									<path d="M342.848,2023.226a2.346,2.346,0,0,1-1.494-.536l-9.49-7.8a2.354,2.354,0,0,1,2.99-3.637l7.776,6.393,16.347-17.638a2.354,2.354,0,1,1,3.453,3.2l-17.854,19.266A2.351,2.351,0,0,1,342.848,2023.226Z" transform="translate(-331.006 -1999.253)" fill="#f0f3fc"/>
								</g>
							</g>
						</g>
					</svg>
					<Button className={styles.WhitepaperBtn} size="large" theme="solid" tone="light" onClick={() => window.open("https://cdn.prestavi.com/Prestavi-Security-Whitepaper.pdf")}>Download Security White Paper</Button>
				</div>
			</div>

			<div className={styles.Container}>
				<div className={styles.InfraProvider}>
					<div>
						<img src="/assets/aws-logo.png" alt=""/>
					</div>
					<div>
						<h2>Enterprise-Grade Security</h2>
						<h1>Powered By AWS</h1>
						<p>The Prestavi platform is built upon the industry-leading cloud hosting provider Amazon Web Services (AWS). Our network infrastructure is secure, scalable, and easily managed.</p>
					</div>
				</div>
			</div>

			<div className={styles.Container}>
				<div className={styles.Features}>
					<div className={styles.Feature}>
						<svg xmlns="http://www.w3.org/2000/svg" width="60" height="56.826" viewBox="0 0 60 56.826">
							<path d="M1406.836,2796.126c-4.637,8.9-2.982,20.4,3.187,28.313,8.769,11.249,26.113,14.551,38.543,7.557,13.362-7.519,19.245-25.387,13.286-39.426-6.379-15.031-18.059-15.369-32.4-12C1420.376,2782.7,1411.3,2787.569,1406.836,2796.126Z" transform="translate(-1404.176 -2778.832)" fill="#fff"/>
							<g transform="translate(19 13)">
								<path d="M536.338,1991.385a7.947,7.947,0,0,0-7.943,7.948v2.856c0,.052,0,.1,0,.152a5.8,5.8,0,0,0,.043.712h2.875a4.476,4.476,0,0,1-.071-.712c0-.052,0-.1,0-.152v-2.856a5.1,5.1,0,1,1,10.2,0v2.856c0,.052,0,.1,0,.152a4.427,4.427,0,0,1-.071.712h2.875a5.8,5.8,0,0,0,.043-.712c.005-.052.005-.1.005-.152v-2.856A7.948,7.948,0,0,0,536.338,1991.385Z" transform="translate(-525.197 -1991.385)" fill="#265dde"/>
								<g transform="translate(0 10.244)">
									<path d="M541.172,2017.735H524.517a2.1,2.1,0,0,0-2.1,2.1v14.372a2.1,2.1,0,0,0,2.1,2.1h16.655a2.1,2.1,0,0,0,2.1-2.1v-14.372A2.1,2.1,0,0,0,541.172,2017.735Zm1.39,16.475a1.389,1.389,0,0,1-1.39,1.39H524.517a1.392,1.392,0,0,1-1.39-1.39v-14.372a1.392,1.392,0,0,1,1.39-1.39h16.655a1.389,1.389,0,0,1,1.39,1.39Z" transform="translate(-521.703 -2017.023)" fill="#5583ed"/>
									<path d="M540.172,2016.023H523.517a2.817,2.817,0,0,0-2.814,2.814v14.373a2.817,2.817,0,0,0,2.814,2.814h16.655a2.817,2.817,0,0,0,2.814-2.814v-14.373A2.817,2.817,0,0,0,540.172,2016.023Zm1.39,17.186a1.389,1.389,0,0,1-1.39,1.39H523.517a1.392,1.392,0,0,1-1.39-1.39v-14.373a1.392,1.392,0,0,1,1.39-1.39h16.655a1.389,1.389,0,0,1,1.39,1.39Z" transform="translate(-520.703 -2016.023)" fill="#5583ed"/>
									<path d="M543.562,2020.837v14.372a1.389,1.389,0,0,1-1.39,1.39H525.517a1.392,1.392,0,0,1-1.39-1.39v-14.372a1.392,1.392,0,0,1,1.39-1.39h16.655A1.389,1.389,0,0,1,543.562,2020.837Z" transform="translate(-522.703 -2018.023)" fill="#5583ed"/>
								</g>
								<circle cx="2.401" cy="2.401" r="2.401" transform="translate(8.74 17.843)" fill="#fff"/>
							</g>
						</svg>
						<h3>Strong Encryption</h3>
						<p>All data sent over public networks (in transit) is encrypted. Within our firewalled private networks, data may be unencrypted. All of your data is always encrypted at rest.</p>
					</div>
					<div className={styles.Feature}>
						<svg xmlns="http://www.w3.org/2000/svg" width="60.759" height="55.512" viewBox="0 0 60.759 55.512">
							<g transform="translate(-240 -1462)">
								<path d="M607.2,3345.205c6.785-7.4,8.164-18.934,4.255-28.176-5.558-13.138-21.457-20.821-35.275-17.284-14.855,3.8-25.166,19.54-23.046,34.645,2.269,16.172,13.464,19.523,28.19,19.983C590.645,3354.668,600.679,3352.316,607.2,3345.205Z" transform="translate(-312.86 -1836.885)" fill="#fff"/>
								<g transform="translate(258 1476)">
									<rect width="18.808" height="22.722" rx="1.311" transform="translate(4.966)" fill="#5583ed"/>
									<rect width="18.808" height="22.722" rx="1.311" transform="translate(0 4.852)" fill="#bbd7f7"/>
								</g>
							</g>
						</svg>


						<h3>Backups & Redundancy</h3>
						<p>All data is written to multiple disks instantly and stored across multiple locations. Backups are automatically performed daily and include point-in-time recovery capabilities.</p>
					</div>

					<div className={styles.Feature}>
						<svg xmlns="http://www.w3.org/2000/svg" width="61.48" height="57.06" viewBox="0 0 61.48 57.06">
							<g transform="translate(-245.261 -1755)">
								<path d="M650.143,3511.062c-9.572,3.014-16.534,12.316-17.767,22.274-1.754,14.155,8.175,28.754,21.91,32.6,14.765,4.132,31.559-4.343,37.273-18.484,6.118-15.139-1.9-23.637-14.425-31.4C669.211,3511.142,659.347,3508.165,650.143,3511.062Z" transform="translate(-386.914 -1754.923)" fill="#fff"/>
								<g>
									<circle cx="13.501" cy="13.501" r="13.501" transform="translate(264.374 1772)" fill="#cdedf7"/>
									<path d="M580.056,533.676v3.4a.182.182,0,0,1-.182.182H569.836a.182.182,0,0,1-.182-.182V527.036a.182.182,0,0,1,.182-.182h3.841a.182.182,0,0,1,.182.182v6.275a.183.183,0,0,0,.182.182h5.832A.183.183,0,0,1,580.056,533.676Z" transform="translate(-293.992 1250.237)" fill="#2dbae0"/>
								</g>
							</g>
						</svg>




						<h3>Constant Monitoring</h3>
						<p>We have around the clock monitoring of every aspect of Prestavi. If something goes wrong, we'll update our Status Page in real-time to keep you in the loop. To date, we've never had a data breach. </p>
					</div>
				</div>
			</div>

			<div className={styles.Compliance}>
				<div className={styles.Policy}>
					<div>
						<div className={styles.Head}>
							<div>
								<h2>GDPR</h2>
								<span className={styles.Line}></span>
							</div>
							<img
								src="/assets/Flag-European-Union.jpeg"
								alt="European Union Flag"/>
						</div>

						<p>
							Prestavi is committed to compliance with the General Data Protection Regulation (GDPR).
						</p>
						<p>
							Learn about how we’ve prepared, our role in GDPR compliance, your data rights, and more.
						</p>
					</div>

					<div className={styles.Action}>
						<Button size="large" theme="solid" tone="light" onClick={() => navigate("/legal/gdpr")}>Learn More
							<svg style={{marginLeft: "12px"}} xmlns="http://www.w3.org/2000/svg" width="16.016" height="14.691" viewBox="0 0 16.016 14.691">
								<g transform="translate(625.696 830.601) rotate(180)">
									<g transform="translate(609.683 822.235)">
										<path d="M624.9,827.742H610.482a1.036,1.036,0,0,1,0-2.016H624.9a1.035,1.035,0,0,1,0,2.016Z" transform="translate(-609.684 -825.726)" fill="#9cb7f7"/>
									</g>
									<g transform="translate(609.68 815.91)">
										<path d="M618.907,828.822a1.008,1.008,0,0,1-.651,1.778,1.015,1.015,0,0,1-.644-.232l-7.571-6.353a.768.768,0,0,1-.122-.129.88.88,0,0,1-.1-.135.049.049,0,0,1-.013-.026.573.573,0,0,1-.052-.11.471.471,0,0,1-.052-.161.89.89,0,0,1,0-.425.621.621,0,0,1,.064-.193.035.035,0,0,1,.019-.032.351.351,0,0,1,.052-.1.425.425,0,0,1,.077-.11,1.211,1.211,0,0,1,.129-.129l.032-.026,7.532-6.3a1.009,1.009,0,1,1,1.3,1.546l-6.65,5.554Z" transform="translate(-609.68 -815.91)" fill="#5583ed"/>
									</g>
								</g>
							</svg>
						</Button>
					</div>
				</div>
				<div className={styles.Policy}>
					<div>
						<div className={styles.Head}>
							<div>
								<h2>Privacy Policy</h2>
								<span className={styles.Line}></span>
							</div>
						</div>

						<div className={styles.Checklist}>
							<p>How we collect and use your data</p>
							<p>Your data rights</p>
							<p>How we transfer your data</p>
							<p>How and when we retain your data</p>
							<p>Our contact details</p>
						</div>
					</div>

					<div className={styles.Action}>
						<Button size="large" theme="solid" tone="light" onClick={() => navigate("/legal/privacy")}>Read Privacy Policy
							<svg style={{marginLeft: "12px"}} xmlns="http://www.w3.org/2000/svg" width="16.016" height="14.691" viewBox="0 0 16.016 14.691">
								<g transform="translate(625.696 830.601) rotate(180)">
									<g transform="translate(609.683 822.235)">
										<path d="M624.9,827.742H610.482a1.036,1.036,0,0,1,0-2.016H624.9a1.035,1.035,0,0,1,0,2.016Z" transform="translate(-609.684 -825.726)" fill="#9cb7f7"/>
									</g>
									<g transform="translate(609.68 815.91)">
										<path d="M618.907,828.822a1.008,1.008,0,0,1-.651,1.778,1.015,1.015,0,0,1-.644-.232l-7.571-6.353a.768.768,0,0,1-.122-.129.88.88,0,0,1-.1-.135.049.049,0,0,1-.013-.026.573.573,0,0,1-.052-.11.471.471,0,0,1-.052-.161.89.89,0,0,1,0-.425.621.621,0,0,1,.064-.193.035.035,0,0,1,.019-.032.351.351,0,0,1,.052-.1.425.425,0,0,1,.077-.11,1.211,1.211,0,0,1,.129-.129l.032-.026,7.532-6.3a1.009,1.009,0,1,1,1.3,1.546l-6.65,5.554Z" transform="translate(-609.68 -815.91)" fill="#5583ed"/>
									</g>
								</g>
							</svg>
						</Button>
					</div>
				</div>
			</div>

			<section className={styles.Support}>
				<h2>Questions? We have answers.</h2>
				<p>
					For any questions related to security, please reach out to us at <span className={styles.Highlight}>support@prestavi.com</span>
				</p>
			</section>
			<Footer />
		</div>
	);
};

export default Security;
