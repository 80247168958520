// extracted by mini-css-extract-plugin
export var Bottom = "Security-module--Bottom--eGUna";
export var Checklist = "Security-module--Checklist--Eh6R0";
export var Compliance = "Security-module--Compliance--56e34";
export var Container = "Security-module--Container--jhrAb";
export var Feature = "Security-module--Feature--6J7Bo";
export var Features = "Security-module--Features--tSeHI";
export var Head = "Security-module--Head--kL6qA";
export var Hero = "Security-module--Hero--IigdP";
export var Highlight = "Security-module--Highlight--Uijki";
export var InfraProvider = "Security-module--InfraProvider--FcudC";
export var Line = "Security-module--Line--abLCE";
export var Policy = "Security-module--Policy--Z3wOK";
export var Security = "Security-module--Security--plGoV";
export var Support = "Security-module--Support--n8wiD";
export var Top = "Security-module--Top--8ZEmt";
export var WhitepaperBtn = "Security-module--WhitepaperBtn--y6QYq";